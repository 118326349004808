import { createStyles, Grid, Title, Text } from "@mantine/core";
import { NextLink } from "@mantine/next";
import {
  IconInfinity,
  IconMultiplier15x,
  IconMultiplier2x,
  IconReload,
} from "@tabler/icons-react";
import Image from "next/image";
import Bracket from "../public/img/bracket.svg";
import PlaylistBlank from "./PlaylistBlank";

const useStyles = createStyles(({ colors, spacing, breakpoints }) => ({
  cross: {
    background: "#333",
    height: "50px",
    position: "relative",
    width: "10px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "&:after": {
      background: "#333",
      content: "''",
      height: "10px",
      left: "-20px",
      position: "absolute",
      top: "20px",
      width: "50px",
    },
  },
  exampleContainer: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  textInput: {
    borderRadius: 10,
    border: "8px solid #333",
    padding: "15px 75px 15px 30px",
    position: "relative",
    display: "inline-block",
    marginTop: "2rem",
  },
  arrowDown: {
    width: 0,
    height: 0,
    borderLeft: "7px solid transparent",
    borderRight: "7px solid transparent",
    borderTop: "10px solid #333",
    position: "absolute",
    right: 20,
    top: "50%",
    transform: "translateY(-50%)",
  },
  podcastImageWrapper: {
    width: "100%",
    position: "relative",
    overflow: "hidden",
    boxShadow: "0 3px 10px #bababa",
    "&:before": {
      content: "''",
      display: "block",
      paddingTop: "100%",
    },
    [`@media (max-width: ${breakpoints.md}px)`]: {
      width: "30%",
      float: "left",
      marginRight: "calc(9% / 2)",
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
  podcastImage: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
    width: "auto",
    height: "100%",
  },
  centerColumn: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "auto",
    display: "flex",
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      height: 130,
    },
    [`@media (max-width: ${breakpoints.md}px)`]: {
      height: 100,
      "& svg": {
        transform: "rotate(90deg)",
        height: "calc(100vw - 80px)",
        width: 100,
      },
    },
  },
}));

export const HowItWorks = () => {
  const {
    classes,
    theme: { colors, spacing },
  } = useStyles();
  return (
    <>
      <div className={classes.exampleContainer}>
        <Grid gutter={60}>
          <Grid.Col
            md={6}
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "auto",
              display: "flex",
            }}
          >
            <Title size={30} mb="xl" order={2}>
              Create a daily{" "}
              <div
                style={{
                  marginBottom: -9,
                  marginLeft: 5,
                  display: "inline-block",
                  height: 40,
                  width: 130,
                  position: "relative",
                }}
              >
                <Image
                  layout="fill"
                  objectFit="contain"
                  src={require("../public/img/spotify-green.png")}
                  alt="Spotify Logo"
                />
              </div>{" "}
              playlist of the podcasts you love!
            </Title>
            <Text size={23}>
              Simply choose your favorite podcasts, what time you want your
              playlist created, and we’ll do the rest!
            </Text>
          </Grid.Col>
          <Grid.Col md={6}>
            <Grid>
              <Grid.Col md={4}>
                <div>
                  <div className={classes.podcastImageWrapper}>
                    <img
                      className={classes.podcastImage}
                      src="https://i.scdn.co/image/ab6765630000ba8ae2b40e2357bfcdd1022646bd"
                      alt="Podcasts in horizontal list"
                    />
                  </div>
                  <Text
                    align="center"
                    size={14}
                    style={{
                      maxWidth: 150,
                      padding: 10,
                      marginBottom: spacing.xs,
                    }}
                  >
                    <NextLink
                      legacyBehavior
                      href="https://open.spotify.com/show/2mTUnDkuKUkhiueKcVWoP0"
                      target="_blank"
                      style={{ color: colors.green[9], textDecoration: "none" }}
                    >
                      Up First
                    </NextLink>
                  </Text>
                </div>
                <div>
                  <div className={classes.podcastImageWrapper}>
                    <img
                      className={classes.podcastImage}
                      src="https://i.scdn.co/image/ab6765630000ba8a1e6feb830289477133c7899f"
                      alt="Podcasts in horizontal list"
                    />
                  </div>
                  <Text
                    align="center"
                    size={14}
                    style={{
                      maxWidth: 150,
                      padding: 10,
                      marginBottom: spacing.xs,
                    }}
                  >
                    <NextLink
                      legacyBehavior
                      href="https://open.spotify.com/show/5RllMBgvDnTau8nnsCUdse"
                      target="_blank"
                      style={{ color: colors.green[9], textDecoration: "none" }}
                    >
                      The Best One Yet
                    </NextLink>
                  </Text>
                </div>
                <div>
                  <div className={classes.podcastImageWrapper}>
                    <img
                      className={classes.podcastImage}
                      src="https://i.scdn.co/image/ab6765630000ba8a578af948a8685681c360510b"
                      alt="Podcasts in horizontal list"
                    />
                  </div>
                  <Text
                    align="center"
                    size={14}
                    style={{
                      maxWidth: 150,
                      padding: 10,
                      marginBottom: spacing.xs,
                    }}
                  >
                    <NextLink
                      legacyBehavior
                      href="https://open.spotify.com/show/6kAsbP8pxwaU2kPibKTuHE"
                      target="_blank"
                      style={{ color: colors.green[9], textDecoration: "none" }}
                    >
                      Armchair Expert with Dax Shepard
                    </NextLink>
                  </Text>
                </div>
              </Grid.Col>
              <Grid.Col className={classes.centerColumn} md={2}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 254.85">
                  <path
                    style={{
                      fill: "none",
                      stroke: "#1db954",
                      strokeMiterlimit: 10,
                      strokeWidth: 5,
                    }}
                    d="m0,2.5s23.51,0,23.51,23.51c0,26.87,0,79.93,0,79.93,0,0-.67,21.49,21.49,21.49-22.16,0-21.49,21.49-21.49,21.49,0,0,0,53.06,0,79.93C23.51,252.35,0,252.35,0,252.35"
                  />
                </svg>
              </Grid.Col>
              <Grid.Col
                md={6}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "auto",
                  display: "flex",
                }}
              >
                <PlaylistBlank />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </div>
    </>
  );
};

export default HowItWorks;
