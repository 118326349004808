import {
  Button,
  Card,
  Container,
  createStyles,
  Grid,
  Text,
  Image,
} from "@mantine/core";
import { IconEdit, IconReload, IconTrash } from "@tabler/icons-react";

const useStyles = createStyles(() => ({}));

export const PlaylistBlank = () => {
  const { classes } = useStyles();
  return (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <Card.Section>
        <Image
          width="100%"
          src="img/morning-drive-playlist-cover.png"
          alt="Example album cover"
        />
      </Card.Section>

      <Text pt="lg">Morning Drive</Text>

      <Text size="sm" color="dimmed">
        7 AM (-6:00)
      </Text>

      <Grid>
        <Grid.Col span={6}>
          <Button
            fullWidth
            variant="light"
            color="red"
            mt="md"
            radius="md"
            title="Delete"
          >
            <IconTrash />
          </Button>
        </Grid.Col>
        <Grid.Col span={6}>
          <Button
            fullWidth
            variant="light"
            color="spotifyGreen"
            mt="md"
            radius="md"
            title="Regenerate"
          >
            <IconReload />
          </Button>
        </Grid.Col>
        <Grid.Col span={12}>
          <Button
            fullWidth
            variant="light"
            color="blue"
            mt="md"
            radius="md"
            title="Edit"
          >
            <IconEdit />
          </Button>
        </Grid.Col>
      </Grid>
    </Card>
  );
};

export default PlaylistBlank;
