import { createStyles, Title, Container, Text, Grid } from "@mantine/core";
import {
  IconInfinity,
  IconMultiplier2x,
  IconReload,
} from "@tabler/icons-react";

import HowItWorks from "../components/HowItWorks";

const useStyles = createStyles(({ breakpoints, colorScheme }) => ({
  upgradeSection: {
    margin: 20,
    padding: "6rem",
    backgroundColor: colorScheme === "light" ? "#e9f8ee" : "#031208",
    borderRadius: 20,
    [`@media (max-width: ${breakpoints.md}px)`]: {
      padding: "6rem 1rem",
    },
  },
}));

export default function Home() {
  const { classes } = useStyles();

  return (
    <>
      <section>
        <Container>
          <HowItWorks />
        </Container>
      </section>

      <section className={classes.upgradeSection}>
        <Container>
          <Title size={30} order={3}>
            Upgrade for additional features
          </Title>
          <Text mt="md" mb="xl" size={22}>
            Transparent pricing of $2 per month or $16 per year.
          </Text>
          <Grid mt="xl" gutter={50}>
            <Grid.Col md={4}>
              <IconMultiplier2x
                size={100}
                style={{ transform: "translateX(-20px)", color: "#1DB954" }}
              />
              <Title order={4}>Multiple generations per day</Title>
              <Text mt="xs">
                Create a fresh playlist for your morning and afternoon commute.
              </Text>
            </Grid.Col>
            <Grid.Col md={4}>
              <IconInfinity
                size={100}
                style={{ transform: "translateX(-5px)", color: "#1DB954" }}
              />
              <Title order={4}>Unlimited playlists</Title>
              <Text mt="xs">
                Create as many playlists as your heart desires.
              </Text>
            </Grid.Col>
            <Grid.Col md={4}>
              <IconReload
                size={100}
                style={{ transform: "translateX(-10px)", color: "#1DB954" }}
              />
              <Title order={4}>On-demand generation</Title>
              <Text mt="xs">
                Unlock the ability to generate your playlist whenever and
                wherever you like.
              </Text>
            </Grid.Col>
          </Grid>
          <Text mt="4rem" align="center" size={23} italic color="dimmed">
            Plus access to feature requests and new features as they become
            available.
          </Text>
        </Container>
      </section>
    </>
  );
}
